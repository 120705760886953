import { useState } from "react";

// Plugins
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "../../Restaurant/Add/style.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#C5C5C5!important",
    borderRadius: "10px",
    minHeight: "40px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    fontSize: "14px",
    padding: "0 15px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginTop: "-3px!important",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "40px",
  }),
};

const roles = [{ value: "role", label: "User Role" }];
const languages = [{ value: "language", label: "Language" }];
const countries = [{ value: "country", label: "Country" }];
const month = [{ value: "month", label: "Month" }];

const HeroButtons = ({ handleSave }) => {
  return (
    <>
      <a href="#" onClick={() => handleSave()}>
        <i class="ri-check-line"></i>
        <span>Save Product</span>
      </a>
    </>
  );
};

const AddProduct = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [activeTab, setTab] = useState("information");

  return (
    <DashboardLayout>
      <PageHero
        hero="ADD PRODUCT"
        buttons={<HeroButtons handleSave={() => alert("save")} />}
      />

      <div className="add-form-container">
        <div className="nav">
          <ul>
            <li className={`${activeTab == "information" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("information")}>
                <i class="ri-information-line"></i> Information
              </a>
            </li>
            <li className={`${activeTab == "images" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("images")}>
                <i class="ri-gallery-line"></i> Images
              </a>
            </li>
            <li className={`${activeTab == "pricing" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("pricing")}>
                <i class="ri-wallet-line"></i> Pricing
              </a>
            </li>
            <li className={`${activeTab == "inventory" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("inventory")}>
                <i class="ri-file-list-line"></i> Inventory
              </a>
            </li>
            <li className={`${activeTab == "shipping" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("shipping")}>
                <i class="ri-archive-line"></i> Shipping
              </a>
            </li>
          </ul>
        </div>
        {activeTab == "information" && (
          <div className="fields">
            <div className="field">
              <div className="label">Product Name :</div>
              <div className="field-content">
                <input type="text" placeholder="Your Product Name" />
              </div>
            </div>
            <div className="field">
              <div className="label">Product Description :</div>
              <div className="field-content">
                <Editor editorStyle={{ height: "400px" }} />
              </div>
            </div>
            <div className="field">
              <div className="label">Brand :</div>
              <div className="field-content">
                <input type="text" placeholder="Info Brand" />
              </div>
            </div>
            <div className="field">
              <div className="label">Delivery Condition :</div>
              <div className="field-content">
                <input type="text" placeholder="Info Delivery Condition" />
              </div>
            </div>
            <div className="field">
              <div className="label">Type :</div>
              <div className="field-content">
                <input type="text" placeholder="Info Type" />
              </div>
            </div>
            <div className="field">
              <div className="label">Style :</div>
              <div className="field-content">
                <input type="text" placeholder="Info Style" />
              </div>
            </div>
            <div className="field">
              <div className="label">Product Number :</div>
              <div className="field-content">
                <input type="text" placeholder="Info Product Number" />
              </div>
            </div>
          </div>
        )}

        {activeTab == "images" && <div className="fields"></div>}

        {activeTab == "pricing" && <div className="fields"></div>}

        {activeTab == "inventory" && <div className="fields"></div>}

        {activeTab == "shipping" && <div className="fields"></div>}
      </div>
    </DashboardLayout>
  );
};

export default AddProduct;

import './style.css'

const Orders = () => {
  return (
    <div className="last-orders">
      <div className="label">Last Order</div>
      <div className="table-area">
        <table>
          <tr>
            <th>Customer Name</th>
            <th>Order No.</th>
            <th>Amount</th>
            <th>Payment Type</th>
            <th>Payment Type</th>
            <th>Date</th>
          </tr>
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>  
          <tr>
            <td>
              <a href="#" className="customer">
                <div className="avatar"></div>
                <span>Cody Fisher</span>
              </a>
            </td>
            <td>#20210511</td>
            <td>$11.70</td>
            <td>Credit Card</td>
            <td>Credit Card</td>
            <td>2020-12-19 <a href="#" className="more"></a></td>
          </tr>                                                                                                    
        </table>
      </div>
    </div>
  );
};

export default Orders;

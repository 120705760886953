import DashboardLayout from "../../layouts/dashboard";

// Components
import Cards from "../../components/Cards";
import ReportChart from "../../components/ReportChart";
import OrderChart from "../../components/OrderChart";
import Orders from "../../components/Orders";

import "./style.css";

const Dashboard = () => {
  return (
    <DashboardLayout type="white">
      <Cards />
      <div className="charts-area">
        <div>
          <ReportChart />
        </div>
        <div>
          <OrderChart />
        </div>
      </div>
      <Orders />
    </DashboardLayout>
  );
};

export default Dashboard;

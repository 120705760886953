import "./style.css";

const Header = ({ handleMenu }) => {
  return (
    <div className="d-header">
      <a href="#" className="toggle" onClick={() => handleMenu()}>
        <i class="ri-menu-2-fill"></i>
      </a>
      <div className="user">
        <ul>
          <li>
            <a href="#" className="notification">
              <i class="ri-notification-line"></i>
              <span></span>
            </a>
          </li>
          <li>
            <a href="#" className="user-toggle">
              <div>
                <strong>John Doe</strong>
                <p>Admin</p>
              </div>
              <div>
                <div className="avatar">
                  <span className="status online"></span>
                </div>
              </div>
            </a>
          </li>          
        </ul>
      </div>
    </div>
  );
};

export default Header;

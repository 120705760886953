import { useState } from "react";

import MainLayout from "./main";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

import "./style.css";

const DashboardLayout = (props) => {
  const [isMenuShown, setMenuVisibility] = useState(true)

  return (
    <MainLayout>
      <div className={`dashboard-container ${props.type} ${!isMenuShown ? 'hide-menu' : ''}`}>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content">
          <Header handleMenu={() => setMenuVisibility(!isMenuShown)} />
          {props.children}
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardLayout;

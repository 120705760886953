import "./style.css";

const GridList = ({ handleModal }) => {
  return (
    <div className="grid-list">
      <div className="items">
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li>Cash</li>
                <li>Paid</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="warning">COD</li>
                <li className="warning">Waiting</li>
              </ul>
            </div>
          </a>
        </div>
        <div className="item">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
            }}
          >
            <div className="icon">
              <i class="ri-sticky-note-2-line"></i>
            </div>
            <div className="label">
              <span>#5036</span>
              Tammy Sanchez
            </div>
            <div className="date">1 Sep 2021</div>
            <div className="bottom">
              <div className="price">$3171</div>
              <ul className="tags">
                <li className="info">Bank Transfer</li>
                <li className="danger">Refund</li>
              </ul>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GridList;

import "./style.css";

const PageHero = ({
  hero,
  searchPlaceholder,
  handleSearch,
  buttons
}) => {
  return (
    <div className="page-hero">
      <div className="hero">{hero}</div>
      {searchPlaceholder && (
        <div className="search">
          <form onSubmit={handleSearch}>
            <input type="text" placeholder={searchPlaceholder} />
            <button>
              <i class="ri-search-line"></i>
            </button>
          </form>
        </div>
      )}
      <div className="actions">
        {buttons}
      </div>
    </div>
  );
};

export default PageHero;

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { IntlProvider } from "react-intl";

// Dashboard
import Dashboard from "./containers/Dashboard";

// Auth
import Login from "./containers/Login";
import Register from "./containers/Register";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";

// Orders
import Orders from "./containers/Orders/List";
import Invoice from "./containers/Orders/Invoice";

// Products
import Categories from "./containers/Products/Categories";
import AddCategory from "./containers/Products/AddCategory";
import Products from "./containers/Products/List";
import ProductDetail from "./containers/Products/ProductDetail";
import AddProduct from "./containers/Products/Add";

// Branch
import Branches from "./containers/Branch/List";
import AddBranch from "./containers/Branch/Add";
import BranchDetail from "./containers/Branch/Detail";
import Zones from "./containers/Branch/Zones";

// Restaurant
import Restaurants from "./containers/Restaurant/List";
import AddRestaurant from "./containers/Restaurant/Add";
import PreviewRestaurant from "./containers/Restaurant/Preview";

// Ticket
import Tickets from "./containers/Ticket/List";

// Users
import Users from "./containers/Users/List";
import AddUser from "./containers/Users/Add";

// Translations
import messages_tr from './translations/tr.json'
import messages_en from './translations/en.json'

const messages = {
  'tr': messages_tr,
  'en': messages_en
};

export default function App() {
  return (
    <Router>
      <IntlProvider messages={messages["tr"]} locale="en" defaultLocale="en">
        <div>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/invoice" element={<Invoice />} />

            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/add" element={<AddCategory />} />

            <Route path="/products" element={<Products />} />
            <Route path="/products/add" element={<AddProduct />} />
            <Route path="/product/detail" element={<ProductDetail />} />

            <Route path="/branches" element={<Branches />} />
            <Route path="/branches/add" element={<AddBranch />} />
            <Route path="/branches/preview" element={<BranchDetail />} />
            <Route path="/zones" element={<Zones />} />

            <Route path="/restaurants" element={<Restaurants />} />
            <Route path="/restaurants/add" element={<AddRestaurant />} />
            <Route path="/restaurants/preview" element={<PreviewRestaurant />} />

            <Route path="/tickets" element={<Tickets />} />

            <Route path="/users" element={<Users />} />
            <Route path="/users/add" element={<AddUser />} />
          </Routes>
        </div>
      </IntlProvider>
    </Router>
  );
}

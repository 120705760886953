import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./style.css";

const Sidebar = () => {
  return (
    <div className="d-sidebar">
      <div className="logo"></div>
      <div className="navigation">
        <ul>
          <li>
            <NavLink to="/">
              <i class="ri-dashboard-line"></i>
              <FormattedMessage
                id="sidebar.dashboard"
                defaultMessage="Dashboard"
              />
            </NavLink>
          </li>
          <li>
            <NavLink to="/products">
              <i class="ri-shopping-cart-line"></i>
              <FormattedMessage
                id="sidebar.ecommerce"
                defaultMessage="E-Commerce"
              />              
            </NavLink>
          </li>
          <li>
            <NavLink to="/orders">
              <i class="ri-calendar-line"></i>
              <FormattedMessage
                id="sidebar.calendar"
                defaultMessage="Calendar"
              />                     
            </NavLink>
          </li>
          <li>
            <NavLink to="/ticket/list">
              <i class="ri-mail-line"></i>
              <FormattedMessage
                id="sidebar.mail"
                defaultMessage="Mail"
              />                                  
            </NavLink>
          </li>
          <li>
            <NavLink to="/ticket/chat">
              <i class="ri-message-2-line"></i>
              <FormattedMessage
                id="sidebar.chat"
                defaultMessage="Chat"
              />                              
            </NavLink>
          </li>
          <li>
            <NavLink to="/tasks">
              <i class="ri-task-line"></i>
              <FormattedMessage
                id="sidebar.tasks"
                defaultMessage="Tasks"
              />                              
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects">
              <i class="ri-file-line"></i>
              <FormattedMessage
                id="sidebar.projects"
                defaultMessage="Projects"
              />                              
            </NavLink>
          </li>
          <li>
            <NavLink to="/file-manager">
              <i class="ri-folder-2-line"></i>
              <FormattedMessage
                id="sidebar.file_manager"
                defaultMessage="File Manager"
              />                              
            </NavLink>
          </li>
          <li>
            <NavLink to="/notes">
              <i class="ri-sticky-note-line"></i>
              <FormattedMessage
                id="sidebar.notes"
                defaultMessage="Notes"
              />                              
            </NavLink>
          </li>
          <li>
            <NavLink to="/users">
              <i class="ri-contacts-book-line"></i>
              <FormattedMessage
                id="sidebar.contacts"
                defaultMessage="Contacts"
              />                              
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

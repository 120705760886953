import { useState } from "react";

// Plugins
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";
import ReportChart from "../../../components/ReportChart";

import "../../Restaurant/Add/style.css";

const HeroButtons = ({ handleSave }) => {
  return (
    <>
      <a href="#" onClick={() => handleSave()}>
        <i class="ri-check-line"></i>
        <span>Save</span>
      </a>
    </>
  );
};

const BranchDetail = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [activeTab, setTab] = useState("profile");

  return (
    <DashboardLayout>
      <PageHero
        hero="COMPANY DETAILS"
        buttons={<HeroButtons handleSave={() => alert("save")} />}
      />

      <div className="add-form-container">
        <div className="nav">
          <ul>
            <li className={`${activeTab == "profile" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("profile")}>
                <i class="ri-user-line"></i> Main Profile
              </a>
            </li>
            <li className={`${activeTab == "branches" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("branches")}>
                <i class="ri-map-pin-line"></i> Branches
              </a>
            </li>
            <li className={`${activeTab == "income" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("income")}>
                <i class="ri-wallet-line"></i> Income Revenue
              </a>
            </li>
            <li className={`${activeTab == "items" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("items")}>
                <i class="ri-mail-send-line"></i> Products
              </a>
            </li>
          </ul>
        </div>
        {activeTab == "profile" && (
          <div className="fields boxes-container">
            <div className="boxes">
              <div className="item">
                <div className="icon">
                  <i class="ri-user-line"></i>
                </div>
                <div className="label">PROFILE DETAILS</div>
                <ul>
                  <li>
                    <span>Full Name</span>
                    <span>: Regina Cooper</span>
                  </li>
                  <li>
                    <span>Email</span>
                    <span>: Regina_Cooper@Mail.Com</span>
                  </li>
                  <li>
                    <span>Phone</span>
                    <span>: +1 (070) 4567–8800</span>
                  </li>
                </ul>
              </div>
              <div className="item">
                <div className="icon">
                  <i class="ri-wallet-line"></i>
                </div>
                <div className="label">PAYMENT DETAILS</div>
                <ul>
                  <li>
                    <span>Card Number</span>
                    <span>: 5890 - 6858 - 6332 - 9843</span>
                  </li>
                  <li>
                    <span>Card Name</span>
                    <span>: Regina Cooper</span>
                  </li>
                  <li>
                    <span>Card Expiry</span>
                    <span>: 12/2023</span>
                  </li>
                </ul>
              </div>
              <div className="item">
                <div className="icon">
                  <i class="ri-map-pin-line"></i>
                </div>
                <div className="label">ADDRESS DETAILS</div>
                <ul>
                  <li>
                    <span>Address Line 1</span>
                    <span>: 993 E. Brewer St. Holtsville</span>
                  </li>
                  <li>
                    <span>City</span>
                    <span>: New York</span>
                  </li>
                  <li>
                    <span>Country</span>
                    <span>: United States</span>
                  </li>
                  <li>
                    <span>State/Region</span>
                    <span>: New York</span>
                  </li>
                  <li>
                    <span>Postcode</span>
                    <span>: 11742</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {activeTab == "branches" && (
          <div className="fields branches-container">
            <div className="branches">
              <div className="item">
                <a href="#">
                  <div className="avatar"></div>
                  <div className="name">Branch Name</div>
                  <div className="desc">Branch Manager Name</div>
                  <div className="price">
                    <i class="ri-wallet-line"></i> $125.78
                  </div>
                  <div className="status active">
                    <span>Active</span>
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <div className="avatar"></div>
                  <div className="name">Branch Name</div>
                  <div className="desc">Branch Manager Name</div>
                  <div className="price">
                    <i class="ri-wallet-line"></i> $125.78
                  </div>
                  <div className="status active">
                    <span>Active</span>
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <div className="avatar"></div>
                  <div className="name">Branch Name</div>
                  <div className="desc">Branch Manager Name</div>
                  <div className="price">
                    <i class="ri-wallet-line"></i> $125.78
                  </div>
                  <div className="status active">
                    <span>Active</span>
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <div className="avatar"></div>
                  <div className="name">Branch Name</div>
                  <div className="desc">Branch Manager Name</div>
                  <div className="price">
                    <i class="ri-wallet-line"></i> $125.78
                  </div>
                  <div className="status active">
                    <span>Active</span>
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <div className="avatar"></div>
                  <div className="name">Branch Name</div>
                  <div className="desc">Branch Manager Name</div>
                  <div className="price">
                    <i class="ri-wallet-line"></i> $125.78
                  </div>
                  <div className="status active">
                    <span>Active</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}

        {activeTab == "income" && (
          <div className="fields transparent">
            <div className="income-area">
              <div className="cards">
                <div className="item">
                  <div className="details">
                    <div className="label">Total Order (Monthly)</div>
                    <div className="value">$ 321.523</div>
                    <div className="rate green">
                      <i class="ri-arrow-up-line"></i> 1.700
                    </div>
                  </div>
                  <div className="icon green">
                    <i class="ri-bar-chart-line"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="details">
                    <div className="label">Total Order (Daily)</div>
                    <div className="value">$ 9.700</div>
                    <div className="rate green">
                      <i class="ri-arrow-up-line"></i> 1.700
                    </div>
                  </div>
                  <div className="icon red">
                    <i class="ri-bar-chart-line"></i>
                  </div>
                </div>
              </div>
              <div className="chart">
                <ReportChart />
              </div>
            </div>
          </div>
        )}

        {activeTab == "items" && <div className="fields"></div>}
      </div>
    </DashboardLayout>
  );
};

export default BranchDetail;

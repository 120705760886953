// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "./style.css";

const HeroButtons = () => {
  return (
    <>
      <a href="#">
        <i class="ri-file-download-line"></i>
        <span>Download</span>
      </a>
      <a href="#">
        <i class="ri-send-plane-line"></i>
        <span>Send Invoice</span>
      </a>
    </>
  );
};

const Invoice = () => {
  return (
    <DashboardLayout>
      <PageHero
        hero="INVOICE"
        addButtonText="Add Order"
        handleAdd={() => {
          alert("add order");
        }}
        buttons={<HeroButtons />}
      />

      <div className="invoice-container">
        <div className="head">
          <div className="company">
            <div className="logo">
              <img src="/imgs/invoice-logo.svg" alt="" />
            </div>
            <p>
              Office 149, 450 South Brand BrooklynSan Diego County, CA 91905,
              USA+1 (123) 456 7891, +44 (876) 543 2198
            </p>
          </div>
          <div className="info">
            <div className="number">Invoice #5036</div>
            <ul>
              <li>
                <span>Date Issued</span>
                <span>19 Apr 2021</span>
              </li>
              <li>
                <span>Due Date</span>
                <span>25 Sep 2021</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="user">
          <div className="subhead">Invoice To:</div>
          <div className="info">
            <p>
              <strong>Brent Jones</strong>
            </p>
            <p>3559 Roosevelt Wilson Lane San Bernardino, CA 92405</p>
            <ul>
              <li>
                <span>Phone</span>
                <span>: (123) 456-7890</span>
              </li>
              <li>
                <span>Mobile</span>
                <span>: (+01) 12345 67890</span>
              </li>
            </ul>
          </div>
          <div className="payment">
            <ul>
              <li>
                <span>Payment Type</span>
                <span>: Credit Card</span>
              </li>
              <li>
                <span>Provider</span>
                <span>: Visa Ending In 2851</span>
              </li>
              <li>
                <span>Provider</span>
                <span>: 02/2020</span>
              </li>
              <li>
                <span>CVV</span>
                <span>: 235</span>
              </li>
            </ul>
          </div>
        </div>
        <table>
          <tr>
            <th>PRODUCT </th>
            <th>PRICE</th>
            <th>Pcs</th>
            <th>TOTAL</th>
          </tr>
          <tr>
            <td>Switch Pro Controller</td>
            <td>$429.99</td>
            <td>1</td>
            <td>$429.99</td>
          </tr>
          <tr>
            <td>Switch Pro Controller</td>
            <td>$429.99</td>
            <td>1</td>
            <td>$429.99</td>
          </tr>
          <tr>
            <td>Switch Pro Controller</td>
            <td>$429.99</td>
            <td>1</td>
            <td>$429.99</td>
          </tr>
        </table>
        <div className="price-summary">
          <ul>
            <li>
              <span>Subtotal:</span>
              <span>$1800</span>
            </li>
            <li>
              <span>Shipping Charge:</span>
              <span>$24</span>
            </li>
            <li>
              <span>Estimated Tax:</span>
              <span>$12</span>
            </li>
            <li>
              <span>Total:</span>
              <span>$429.99</span>
            </li>
          </ul>
        </div>
        <div className="note">
          <p>
            <strong>Note:</strong> It was a pleasure working with you and your
            team. We hope you will keep us in mind for future freelance
            projects. Thank You!
          </p>
        </div>
        <ul className="buttons">
          <li>
            <a href="#">
              <i class="ri-edit-box-line"></i> Edit
            </a>
          </li>
          <li>
            <a href="#">
              <i class="ri-printer-line"></i> Print
            </a>
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default Invoice;

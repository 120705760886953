import { useState } from "react";

// Plugins
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "../../Restaurant/Add/style.css";

const HeroButtons = ({ handleSave }) => {
  return (
    <>
      <a href="#" onClick={() => handleSave()}>
        <i class="ri-check-line"></i>
        <span>Save Category</span>
      </a>
    </>
  );
};

const AddCategory = () => {
  return (
    <DashboardLayout>
      <PageHero
        hero="ADD CATEGORY"
        buttons={<HeroButtons handleSave={() => alert("save")} />}
      />

      <div className="add-form-container add-category">
        <div className="fields">
          <div className="field">
            <div className="label">Product Name :</div>
            <div className="field-content">
              <input type="text" placeholder="Your Product Name" />
            </div>
          </div>
          <div className="field">
            <div className="label">Product Description :</div>
            <div className="field-content">
              <Editor editorStyle={{ height: '400px' }} />
            </div>
          </div>
          <div className="multi-field">
            <div className="col">
              <div className="field">
                <div className="label">Product Price :</div>
                <div className="field-content">
                  <input type="text" placeholder="Normal Price" />
                </div>
              </div>
              <div className="field">
                <div className="label">Price :</div>
                <div className="field-content">
                  <input type="text" placeholder="Sale Price" />
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="label">Brand :</div>
            <div className="field-content">
              <input type="text" placeholder="Info Brand" />
            </div>
          </div>
          <div className="field">
            <div className="label">Delivery Condition :</div>
            <div className="field-content">
              <input type="text" placeholder="Info Delivery Condition" />
            </div>
          </div>
          <div className="field">
            <div className="label">Type :</div>
            <div className="field-content">
              <input type="text" placeholder="Info Type" />
            </div>
          </div>
          <div className="field">
            <div className="label">Style :</div>
            <div className="field-content">
              <input type="text" placeholder="Info Style" />
            </div>
          </div>
          <div className="field">
            <div className="label">Product Number :</div>
            <div className="field-content">
              <input type="text" placeholder="Info Product Number" />
            </div>
          </div>
        </div>

        <div className="photos-sidebar">
          <div className="photo-area">
            <div className="label">Product Photo :</div>
            <div className="placeholder" />
          </div>
          <div className="thumbnails-area">
            <div className="label">Product Galery :</div>
            <ul>
              <li>
                <div className="placeholder" />
                <input type="file" />
              </li>
              <li>
                <div className="placeholder" />
                <input type="file" />
              </li>
              <li>
                <div className="placeholder" />
                <input type="file" />
              </li>
              <li>
                <div className="placeholder" />
                <input type="file" />
              </li>                                          
            </ul>
          </div>          
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCategory;

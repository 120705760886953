import AuthLayout from "../../layouts/auth";

const ResetPassword = () => {
  return (
    <AuthLayout>
      <img src="/imgs/forgot-password.png" alt="" className="field-image" />
      <div className="hero">Reset Your Password</div>
      <form>
        <div className="field">
          <input type="password" placeholder="New Password" />
        </div>
        <div className="field">
          <input type="password" placeholder="Confirm New Password" />
        </div>        
        <button type="submit">Reset Password</button>
        <div className="have-account m">
          Go Back To <a href="#">Login</a>
        </div>        
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;

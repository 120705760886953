import { useState } from "react";

// Plugins
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "../../Restaurant/Add/style.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#C5C5C5!important",
    borderRadius: "10px",
    minHeight: "40px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    fontSize: "14px",
    padding: "0 15px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginTop: "-3px!important",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "40px",
  }),
};

const roles = [{ value: "role", label: "User Role" }];
const languages = [{ value: "language", label: "Language" }];
const countries = [{ value: "country", label: "Country" }];
const month = [{ value: "month", label: "Month" }];

const HeroButtons = ({ handleSave }) => {
  return (
    <>
      <a href="#" onClick={() => handleSave()}>
        <i class="ri-check-line"></i>
        <span>Save</span>
      </a>
    </>
  );
};

const AddUser = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [activeTab, setTab] = useState("profile");

  return (
    <DashboardLayout>
      <PageHero
        hero="ADD USER"
        buttons={<HeroButtons handleSave={() => alert("save")} />}
      />

      <div className="add-form-container">
        <div className="nav">
          <ul>
            <li className={`${activeTab == "profile" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("profile")}>
                <i class="ri-user-line"></i> Profile
              </a>
            </li>
          </ul>
        </div>
        {activeTab == "profile" && (
          <div className="fields">
            <div className="status-field">
              <div className="label">Status :</div>
              <span className="status active">Active</span>
              <a href="#">
                <i class="ri-edit-box-line"></i>
              </a>
            </div>
            <div className="field">
              <div className="label">Title :</div>
              <div className="field-content">
                <div className="upload-avatar">
                  <div className="preview" />
                  <span>
                    <i class="ri-edit-box-line"></i>
                  </span>
                  <input type="file" />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Username :</div>
              <div className="field-content">
                <input type="text" placeholder="Username" />
              </div>
            </div>
            <div className="field">
              <div className="label">User Role :</div>
              <div className="field-content">
                <Select options={roles} styles={customStyles} />
              </div>
            </div>
            <div className="field">
              <div className="label">Full Name :</div>
              <div className="field-content">
                <input type="text" placeholder="Full Name" />
              </div>
            </div>
            <div className="field">
              <div className="label">Birth Date :</div>
              <div className="field-content">
                <div className="datepicker-wrap">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  <span>
                    <i class="ri-calendar-line"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Gender :</div>
              <div className="field-content">
                <div className="radios-input">
                  <label>
                    <input type="radio" name="gender" />
                    Male
                  </label>
                  <label>
                    <input type="radio" name="gender" />
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Company :</div>
              <div className="field-content">
                <input type="text" placeholder="Company" />
              </div>
            </div>
            <div className="field">
              <div className="label">Language :</div>
              <div className="field-content">
                <Select options={languages} styles={customStyles} />
              </div>
            </div>
          </div>
        )}

      </div>
    </DashboardLayout>
  );
};

export default AddUser;

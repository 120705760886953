import "./style.css";

const OrderDetail = ({ handleClose }) => {
  return (
    <div className="order-detail-modal">
      <div className="backdrop" onClick={() => handleClose()} />
      <div className="content-area">
        <div className="buttons">
          <a href="#">
            <i class="ri-printer-line"></i> <span>Print</span>
          </a>
          <a href="#">
            <i class="ri-send-plane-line"></i>
            <span>Invoice</span>
          </a>
        </div>
        <div className="tracking">
          <div className="label">Track Order:</div>
          <div className="id">Tracking ID : 894152012012</div>

          <div className="stepper">
            <ul>
              <li className="active">
                <div className="step">
                  <i class="ri-check-line"></i>
                </div>

                <div className="subhead">Order Placed</div>
                <p>April 21 2019</p>
                <p> 07:22 AM</p>
              </li>
              <li>
                <div className="step">
                  <i class="ri-check-line"></i>
                </div>

                <div className="subhead">Packed</div>
                <p>April 21 2019</p>
                <p> 07:22 AM</p>
              </li>
              <li>
                <div className="step">
                  <i class="ri-check-line"></i>
                </div>

                <div className="subhead">Shipped</div>
                <p>April 21 2019</p>
                <p> 07:22 AM</p>
              </li>
              <li>
                <div className="step">
                  <i class="ri-check-line"></i>
                </div>

                <div className="subhead">Delivered</div>
                <p>April 21 2019</p>
                <p> 07:22 AM</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="order">
          <div className="label">Order:</div>
          <table className="p-table">
            <tr>
              <td>
                <div className="name">
                  <div className="img" />
                  <span>Food Name</span>
                </div>
              </td>
              <td>$12,43</td>
              <td>1x</td>
              <td>$12,43</td>
            </tr>
            <tr>
              <td>
                <div className="name">
                  <div className="img" />
                  <span>Food Name</span>
                </div>
              </td>
              <td>$12,43</td>
              <td>1x</td>
              <td>$12,43</td>
            </tr>
          </table>
          <div className="summary">
            <table>
              <tr>
                <td>Sub Total:</td>
                <td>$24,86</td>
              </tr>
              <tr>
                <td>Shipping Charge:</td>
                <td>$24</td>
              </tr>
              <tr>
                <td>Estimated Tax:</td>
                <td>$12</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>$27,86</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="cols">
          <div className="col">
            <div className="delivery-info">
              <div className="label">Delivery Info:</div>
              <div className="name">
                <div className="logo" />
                <span>Pwa Delivery</span>
              </div>
              <ul>
                <li>
                  <span>Order ID</span>
                  <span>: (123) 456-7890</span>
                </li>
                <li>
                  <span>Payment Mode</span>
                  <span>: (+01) 12345 67890</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="info-box">
              <div className="label">Shipping Information:</div>
              <div className="subhead">Brent Jones</div>
              <p>3559 Roosevelt Wilson Lane San Bernardino, CA 92405</p>
              <ul>
                <li>
                  <span>Phone</span>
                  <span>: (123) 456-7890</span>
                </li>
                <li>
                  <span>Mobile</span>
                  <span>: (+01) 12345 67890</span>
                </li>
              </ul>
            </div>
            <div className="info-box">
              <div className="label">Billing Information:</div>
              <ul>
                <li>
                  <span>Payment Type</span>
                  <span>: Credit Card</span>
                </li>
                <li>
                  <span>Provider</span>
                  <span>: Visa Ending In 2851</span>
                </li>
                <li>
                  <span>Provider</span>
                  <span>: 02/2020</span>
                </li>
                <li>
                  <span>CVV</span>
                  <span>: 235</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;

import { useState } from "react";

// Plugins
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "./style.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#C5C5C5!important",
    borderRadius: "10px",
    minHeight: "40px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    fontSize: "14px",
    padding: "0 15px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginTop: "-3px!important",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "40px",
  }),
};

const roles = [{ value: "role", label: "User Role" }];
const languages = [{ value: "language", label: "Language" }];
const countries = [{ value: "country", label: "Country" }];
const month = [{ value: "month", label: "Month" }];

const HeroButtons = ({ handleSave }) => {
  return (
    <>
      <a href="#" onClick={() => handleSave()}>
        <i class="ri-check-line"></i>
        <span>Save</span>
      </a>
    </>
  );
};

const AddRestaurant = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [activeTab, setTab] = useState("profile");

  return (
    <DashboardLayout>
      <PageHero
        hero="ADD COMPANY"
        buttons={<HeroButtons handleSave={() => alert("save")} />}
      />

      <div className="add-form-container">
        <div className="nav">
          <ul>
            <li className={`${activeTab == "profile" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("profile")}>
                <i class="ri-user-line"></i> Profile
              </a>
            </li>
            <li className={`${activeTab == "address" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("address")}>
                <i class="ri-map-pin-line"></i> Address
              </a>
            </li>
            <li className={`${activeTab == "payment" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("payment")}>
                <i class="ri-wallet-line"></i> Payment
              </a>
            </li>
            <li className={`${activeTab == "submission" ? "active" : ""}`}>
              <a href="#" onClick={() => setTab("submission")}>
                <i class="ri-mail-send-line"></i> Submission
              </a>
            </li>
          </ul>
        </div>
        {/* Profile */}
        {activeTab == "profile" && (
          <div className="fields">
            <div className="status-field">
              <div className="label">Status :</div>
              <span className="status active">Active</span>
              <a href="#">
                <i class="ri-edit-box-line"></i>
              </a>
            </div>
            <div className="field">
              <div className="label">Title :</div>
              <div className="field-content">
                <div className="upload-avatar">
                  <div className="preview" />
                  <span>
                    <i class="ri-edit-box-line"></i>
                  </span>
                  <input type="file" />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Username :</div>
              <div className="field-content">
                <input type="text" placeholder="Username" />
              </div>
            </div>
            <div className="field">
              <div className="label">User Role :</div>
              <div className="field-content">
                <Select options={roles} styles={customStyles} />
              </div>
            </div>
            <div className="field">
              <div className="label">Full Name :</div>
              <div className="field-content">
                <input type="text" placeholder="Full Name" />
              </div>
            </div>
            <div className="field">
              <div className="label">Birth Date :</div>
              <div className="field-content">
                <div className="datepicker-wrap">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  <span>
                    <i class="ri-calendar-line"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Gender :</div>
              <div className="field-content">
                <div className="radios-input">
                  <label>
                    <input type="radio" name="gender" />
                    Male
                  </label>
                  <label>
                    <input type="radio" name="gender" />
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Company :</div>
              <div className="field-content">
                <input type="text" placeholder="Company" />
              </div>
            </div>
            <div className="field">
              <div className="label">Language :</div>
              <div className="field-content">
                <Select options={languages} styles={customStyles} />
              </div>
            </div>
          </div>
        )}

        {/* Address */}
        {activeTab == "address" && (
          <div className="fields">
            <div className="field">
              <div className="label">Address Line 1 :</div>
              <div className="field-content">
                <input type="text" placeholder="Address Line 1" />
              </div>
            </div>
            <div className="field">
              <div className="label">Address Line 2 :</div>
              <div className="field-content">
                <input type="text" placeholder="Address Line 2" />
              </div>
            </div>
            <div className="multi-field">
              <div className="col">
                <div className="field">
                  <div className="label">City :</div>
                  <div className="field-content">
                    <input type="text" placeholder="City" />
                  </div>
                </div>
                <div className="field">
                  <div className="label">State :</div>
                  <div className="field-content">
                    <input type="text" placeholder="State" />
                  </div>
                </div>
              </div>
            </div>
            <div className="multi-field">
              <div className="col">
                <div className="field">
                  <div className="label">City :</div>
                  <div className="field-content">
                    <Select options={countries} styles={customStyles} />
                  </div>
                </div>
                <div className="field">
                  <div className="label">Postcode :</div>
                  <div className="field-content">
                    <input type="text" placeholder="Postcode" />
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="label">Email :</div>
              <div className="field-content">
                <input type="text" placeholder="Email" />
              </div>
            </div>
            <div className="field">
              <div className="label">Mobile :</div>
              <div className="field-content">
                <input type="text" placeholder="+62" />
              </div>
            </div>
            <div className="field">
              <div className="label">Website :</div>
              <div className="field-content">
                <input type="text" placeholder="https://" />
              </div>
            </div>
            <div className="field">
              <div className="label">Contact Options :</div>
              <div className="field-content">
                <div className="radios-input">
                  <label>
                    <input type="checkbox" name="type" />
                    Email
                  </label>
                  <label>
                    <input type="checkbox" name="type" />
                    Message
                  </label>
                  <label>
                    <input type="checkbox" name="type" />
                    Phone
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Payment */}
        {activeTab == "payment" && (
          <div className="fields">
            <div className="field">
              <div className="label">Card Number :</div>
              <div className="field-content">
                <input type="number" placeholder="Card Number" />
              </div>
            </div>
            <div className="field">
              <div className="label">Card Holder :</div>
              <div className="field-content">
                <input type="text" placeholder="Card Holder" />
              </div>
            </div>
            <div className="multi-field">
              <div className="col">
                <div className="field">
                  <div className="label">Month :</div>
                  <div className="field-content">
                    <Select options={month} styles={customStyles} />
                  </div>
                </div>
                <div className="field">
                  <div className="label">Year :</div>
                  <div className="field-content">
                    <input type="text" placeholder="Year" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Submission */}
        {activeTab == "submission" && (
          <div className="fields boxes-container">
            <div className="boxes">
              <div className="item">
                <div className="icon">
                  <i class="ri-user-line"></i>
                </div>
                <div className="label">PROFILE DETAILS</div>
                <ul>
                  <li>
                    <span>Full Name</span>
                    <span>: Regina Cooper</span>
                  </li>
                  <li>
                    <span>Email</span>
                    <span>: Regina_Cooper@Mail.Com</span>
                  </li>
                  <li>
                    <span>Phone</span>
                    <span>: +1 (070) 4567–8800</span>
                  </li>
                </ul>
              </div>
              <div className="item">
                <div className="icon">
                  <i class="ri-wallet-line"></i>
                </div>
                <div className="label">PAYMENT DETAILS</div>
                <ul>
                  <li>
                    <span>Card Number</span>
                    <span>: 5890 - 6858 - 6332 - 9843</span>
                  </li>
                  <li>
                    <span>Card Name</span>
                    <span>: Regina Cooper</span>
                  </li>
                  <li>
                    <span>Card Expiry</span>
                    <span>: 12/2023</span>
                  </li>
                </ul>
              </div>
              <div className="item">
                <div className="icon">
                  <i class="ri-map-pin-line"></i>
                </div>
                <div className="label">ADDRESS DETAILS</div>
                <ul>
                  <li>
                    <span>Address Line 1</span>
                    <span>: 993 E. Brewer St. Holtsville</span>
                  </li>
                  <li>
                    <span>City</span>
                    <span>: New York</span>
                  </li>
                  <li>
                    <span>Country</span>
                    <span>: United States</span>
                  </li>
                  <li>
                    <span>State/Region</span>
                    <span>: New York</span>
                  </li>
                  <li>
                    <span>Postcode</span>
                    <span>: 11742</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AddRestaurant;

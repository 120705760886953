import AuthLayout from "../../layouts/auth";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <img src="/imgs/forgot-password.png" alt="" className="field-image" />
      <div className="hero">Recover Your Password</div>
      <form>
        <div className="field">
          <input type="email" placeholder="Email" />
        </div>
        <button type="submit">Send Reset Password</button>
        <div className="have-account m">
          Go Back To <a href="#">Login</a>
        </div>        
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;

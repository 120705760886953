import { useState } from "react";

import "./style.css";

const TableActions = ({ type, handleType }) => {
  const [activeType, setType] = useState("all");

  return (
    <div class="table-actions">
      <div className="view">
        <ul>
          <li
            className={type == "grid" ? "active" : null}
            onClick={() => handleType("grid")}
          >
            <i class="ri-grid-line"></i>
          </li>
          <li
            className={type == "list" ? "active" : null}
            onClick={() => handleType("list")}
          >
            <i class="ri-list-check"></i>
          </li>
        </ul>
      </div>
      <div className="types">
        <ul>
          <li
            className={activeType == "all" ? "active" : null}
            onClick={() => setType("all")}
          >
            All
          </li>
          <li
            className={activeType == "paid" ? "active" : null}
            onClick={() => setType("paid")}
          >
            Paid
          </li>
          <li
            className={activeType == "waiting" ? "active" : null}
            onClick={() => setType("waiting")}
          >
            Waiting
          </li>
          <li
            className={activeType == "refund" ? "active" : null}
            onClick={() => setType("refund")}
          >
            Refund
          </li>
        </ul>
      </div>
      <div className="filters">
        <form>
          <select>
            <option>Date</option>
          </select>
          <select>
            <option>Priority</option>
          </select>
          <select>
            <option>Type</option>
          </select>
          <select>
            <option>Category</option>
          </select>
        </form>
      </div>
    </div>
  );
};

export default TableActions;

import { useState } from "react";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";

import "./style.css";

const HeroButtons = ({ handleAdd }) => {
  return (
    <>
      <a href="#" onClick={() => handleAdd()}>
        <i class="ri-edit-box-line"></i>
        <span>Edit</span>
      </a>
    </>
  );
};

const ProductDetail = () => {
  return (
    <DashboardLayout>
      <PageHero
        hero="Product Details"
        searchPlaceholder="Search Product"
        handleSearch={() => {
          alert("search");
        }}
        buttons={<HeroButtons handleAdd={() => alert("add")} />}
      />

      <div className="product-detail">
        <div className="images">
          <div className="preview" />
        </div>
        <div className="info">
          <div className="name">Switch Pro Controller</div>
          <p>
            The Nintendo Switch Pro Controller On Retina display that never
            sleeps, so it’s easy to see the time and other important
            information, without raising or tapping the display. New location
            features, from a built-in compass to current elevation, help users
            better navigate their day, while international emergency calling1
            allows customers to call emergency services directly from Apple
            Watch in over{" "}
          </p>
          <div className="price">$429.99</div>
        </div>
        <div className="properties">
          <div className="label">General Info</div>
          <ul>
            <li>
              <span>Brand</span>
              <span>Info Brand</span>
            </li>
            <li>
              <span>Delivery Condition</span>
              <span>Info Delivery Condition</span>
            </li>
            <li>
              <span>Type</span>
              <span>Info Type</span>
            </li>
            <li>
              <span>Style</span>
              <span>Info Style</span>
            </li>
            <li>
              <span>Product Number</span>
              <span>Info Product Number</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="related-products">
        <div className="label">Related Products</div>
        <div className="items">
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">The all-in-one for all. If you can drea ...</div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">The all-in-one for all. If you can drea ...</div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">The all-in-one for all. If you can drea ...</div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">The all-in-one for all. If you can drea ...</div>
            <div className="price">$999.99</div>
          </a>                              
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProductDetail;

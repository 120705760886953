import Chart from "react-apexcharts";

import "./style.css";

const series = [44, 55, 41];
const options = {
  chart: {
    type: "donut",    
  },
  plotOptions: {
    pie: {
      donut: {
        size: "85%"
      }
    }
  },  
  stroke: {
    width: 0,
  },
  colors: ["#28C66F", "#28c66f47", "#28c66f47"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 220,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const OrderChart = () => {
  return (
    <div className="order-chart">
      <div className="label">Monthly Order</div>
      <div className="chart-wrap">
        <Chart options={options} series={series} type="donut" height={220} />
        <div className="details">
          <div>56%</div>
          <p>Aim</p>
        </div>
      </div>
      <div className="info-area">
        <div className="amount">
          <span>Order Amount</span>
          <p>3153</p>
        </div>
        <div className="stats">
          <span><i class="ri-arrow-up-line"></i> 23%</span>
          <p>More Than Last Month</p>
        </div>
      </div>
    </div>
  );
};

export default OrderChart;

import "./style.css";

const TableList = ({ handleModal }) => {
  return (
    <div className="table-list">
      <table>
        <tr>
          <th>
            <div>Order ID</div>
          </th>
          <th>
            <div>Client Name</div>
          </th>
          <th>
            <div>Date</div>
          </th>
          <th>
            <div>Payment Method</div>
          </th>
          <th>
            <div>Payment Status</div>
          </th>
          <th>
            <div>Total</div>
          </th>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Cash</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Paid</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number warning">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">COD</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">Waiting</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number danger">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge info">Bank Transfer</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge danger">Refund</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Cash</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Paid</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number warning">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">COD</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">Waiting</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number danger">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge info">Bank Transfer</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge danger">Refund</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Cash</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Paid</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number warning">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">COD</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">Waiting</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number danger">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge info">Bank Transfer</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge danger">Refund</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Cash</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge">Paid</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number warning">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">COD</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge warning">Waiting</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <div className="o-number danger">
                <div className="icon">
                  <i class="ri-sticky-note-2-line"></i>
                </div>
                <span>#5036</span>
              </div>
            </div>
          </td>
          <td>
            <div>Andrew Burns</div>
          </td>
          <td>
            <div>
              <div className="date">1 Sep 2021</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge info">Bank Transfer</div>
            </div>
          </td>
          <td>
            <div>
              <div className="badge danger">Refund</div>
            </div>
          </td>
          <td>
            <div>
              <div className="price">$3171</div>
              <a
                href="#"
                className="more"
                onClick={(e) => {
                  e.preventDefault();
                  handleModal();
                }}
              ></a>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TableList;

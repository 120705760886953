import MainLayout from "./main";

import './style.css'

const AuthLayout = (props) => {
  return (
    <MainLayout>
      <div className="auth-layout">
        <div className="form-area">
          <img src="/imgs/logo.svg" alt="" />
          <div className="form">
          {props.children}
          </div>
        </div>
        <div className="image"></div>
      </div>
    </MainLayout>
  );
};

export default AuthLayout;

import { useState } from "react";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";
import TableActions from "../../../components/TableActions";

import "./style.css";

const HeroButtons = ({ handleAdd }) => {
  return (
    <>
      <a href="#" onClick={() => handleAdd()}>
        <i class="ri-add-fill"></i>
        <span>Add Product</span>
      </a>
    </>
  );
};

const Categories = () => {
  const [viewType, setViewType] = useState("list");

  return (
    <DashboardLayout>
      <PageHero
        hero="Products"
        searchPlaceholder="Search Product"
        handleSearch={() => {
          alert("search");
        }}
        buttons={<HeroButtons handleAdd={() => alert("add")} />}
      />
      <TableActions
        type={viewType}
        handleType={(value) => setViewType(value)}
      />

      <div className="products-list">
        <div className="items">
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>
          <a href="#" className="item">
            <div className="image">
              <div className="preview" />
            </div>
            <div className="name">Apple Imac 27-Inch</div>
            <div className="desc">
              The all-in-one for all. If you can drea ...
            </div>
            <div className="price">$999.99</div>
          </a>                    
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Categories;

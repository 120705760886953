import Chart from "react-apexcharts";

import "./style.css";

const series = [{
  name: 'Total Orders',
  data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
}, {
  name: 'Completed Orders',
  data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
}, {
  name: 'Canceled Orders',
  data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
}];

const options = {
  colors: ["#6259CE", "#28C66F", "#FFAB00"],
  chart: {
    type: 'bar',
    height: 350
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep'],
  },
  yaxis: {
    title: {
      text: 'Revenue Report'
    }
  },
  fill: {
    opacity: 1
  },

};

const ReportChart = () => {
  return (
    <div className="reports-card">
      <div className="label">Revenue Report</div>
      <ul className="legends">
        <li>
          <span></span>
          Total Orders
        </li>
        <li>
          <span></span>
          Completed Orders
        </li>
        <li>
          <span></span>
          Canceled Orders
        </li>                
      </ul>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ReportChart;

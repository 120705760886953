import { useState } from "react";

// Layouts
import DashboardLayout from "../../../layouts/dashboard";

// Components
import PageHero from "../../../components/PageHero";
import TableActions from "../../../components/TableActions";
import GridList from "../../../components/GridList";
import TableList from "../../../components/TableList";

const HeroButtons = ({ handleAdd }) => {
  return (
    <>
      <a href="#" onClick={() => handleAdd()}>
        <i class="ri-add-fill"></i>
        <span>Add Zone</span>
      </a>
    </>
  );
};

const BranchList = () => {
  const [viewType, setViewType] = useState("grid");

  return (
    <DashboardLayout>
      <PageHero
        hero="Zones"
        searchPlaceholder="Search Order"
        handleSearch={() => {
          alert("search");
        }}
        buttons={<HeroButtons handleAdd={() => alert('add')} />}
      />
      <TableActions
        type={viewType}
        handleType={(value) => setViewType(value)}
      />
      {viewType == "grid" && (
        <GridList handleModal={() => {}} />
      )}
      {viewType == "list" && (
        <TableList handleModal={() => {}} />
      )}
    </DashboardLayout>
  );
};

export default BranchList;

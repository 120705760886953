import "./style.css";

const Cards = () => {
  return (
    <div className="cards">
      <div className="item">
        <div className="details">
          <div className="label">Total Order (Monthly)</div>
          <div className="value">$ 321.523</div>
          <div className="rate green">
            <i class="ri-arrow-up-line"></i> 1.700
          </div>
        </div>
        <div className="icon green">
          <i class="ri-bar-chart-line"></i>
        </div>
      </div>
      <div className="item">
        <div className="details">
          <div className="label">Total Order (Weekly)</div>
          <div className="value">$ 67.823</div>
          <div className="rate red">
            <i class="ri-arrow-down-line"></i> 1.700
          </div>
        </div>
        <div className="icon orange">
          <i class="ri-bar-chart-line"></i>
        </div>
      </div>  
      <div className="item">
        <div className="details">
          <div className="label">Total Order (Daily)</div>
          <div className="value">$ 9.700</div>
          <div className="rate green">
            <i class="ri-arrow-up-line"></i> 1.700
          </div>
        </div>
        <div className="icon red">
          <i class="ri-bar-chart-line"></i>
        </div>
      </div>            
    </div>
  );
};

export default Cards;

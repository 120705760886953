import AuthLayout from "../../layouts/auth";

const Register = () => {
  return (
    <AuthLayout>
      <div className="hero">Create Account</div>
      <form>
        <div className="field">
          <input type="text" placeholder="Full Name" />
        </div>
        <div className="field">
          <input type="email" placeholder="Email" />
        </div>        
        <div className="field">
          <input type="password" placeholder="Password" />
        </div>        
        <div className="field">
          <input type="password" placeholder="Confirm Password" />
        </div>                
        <div className="remember">
          <label>
            <input type="checkbox" />
            I Accept <a href="#">Terms And Conditions</a>
          </label>
        </div>         
        <button type="submit">Create Account</button>
        <div className="have-account">
          Already Have An Account? <a href="#">Login</a>
        </div>        

        <div className="social-media-login">
          <div className="label">
            <span>Or Sign Up With</span>
          </div>
          <ul>
            <li>
              <a href="#" className="google">Login With Google</a>
            </li>
          </ul>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Register;

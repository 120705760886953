import AuthLayout from "../../layouts/auth";

const Login = () => {
  return (
    <AuthLayout>
      <div className="hero">Login To Your Account</div>
      <form>
        <div className="field">
          <input type="email" placeholder="Email" />
        </div>
        <div className="field">
          <input type="password" placeholder="Password" />
        </div>        
        <div className="remember">
          <label>
            <input type="checkbox" />
            Remember Me
          </label>
        </div>         
        <button type="submit">Login</button>
        <div className="forgot">
          <a href="#">Forgot Your Password?</a>
        </div>
        <div className="have-account">
          Don't Have An Account? <a href="#">Sign Up</a>
        </div>        

        <div className="social-media-login">
          <div className="label">
            <span>Or Login With</span>
          </div>
          <ul>
            <li>
              <a href="#" className="google">Login With Google</a>
            </li>
          </ul>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
